.about-middle {
  width: 600px;
	height: 300px;
	position: absolute;
	top: -50px;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

.speech-bubble {
  margin: 20px;
  margin-bottom: 10px;
  font-size: 14px;
  padding: 20px;
	position: relative;
	background: white;
	border-radius: .4em;
}

.speech-bubble:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 75%;
	width: 0;
	height: 0;
	border: 21px solid transparent;
	border-top-color: white;
	border-bottom: 0;
	border-left: 0;
	margin-left: -10.5px;
	margin-bottom: -21px;
}

.robot-section {
	display: flex;
	flex-direction: column-reverse;
}

.flex-middle {
	display: flex;
}

@media only screen and (max-width: 600px) {
	.flex-middle {
		flex-direction: column-reverse;
		margin-bottom: 40px;
	}
	.about-middle {
		width: 100vw;
	}
	.robot-section {
		text-align: center;
	}
	.robot-section>img {
		transform: translateX(-50%);
		margin-left: 50%;
	}
}