.form-middle {
  width: 320px;
	height: 300px;
	position: absolute;
	top: -50px;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

.form-middle-inner {
	background-color: rgba(255,255,255,0.9);
	padding: 20px;
}