
body {
  background-color: rgba(131, 230, 255, 0.25) !important;
}

@font-face {
  font-family: 'rubik' ;
  src: local('rubik'), url(./Rubik-Regular.ttf) format('truetype');
}

h1 {
  font-family: 'rubik' !important;
}

body {
  font-family: 'rubik' !important;
}

a {
  color: rgb(0, 162, 255) !important;
}

a:hover {
  cursor: pointer;
}

.background-colors {
  width: 100%;
  height: 110px;
  top: 0px;
  background: linear-gradient(-55deg,#FF0055 25%, #61C6FF 25%, #61C6FF 50%, #31F7C8 50%, #31F7C8 75%, #FFD542 75%);
  position: absolute;
  z-index: -1;
}

.background-colors-faded {
  width: 100%;
  height: 110px;
  top: 110px;
  left: -77px;
  background: linear-gradient(-55deg,rgba(0,0,0,0) 25%, rgba(97, 197, 255, 0.75) 25%, rgba(97, 197, 255, 0.75) 50%, rgba(49, 247, 201, 0.75) 50%, rgba(49, 247, 201, 0.75) 75%, rgba(255, 214, 66, 0.75) 75%);
  position: absolute;
  z-index: -1;
}
.background-colors-faded-2 {
  width: 100%;
  height: 110px;
  top: 220px;
  left: -154px;
  background: linear-gradient(-55deg,rgba(0,0,0,0) 50%, rgba(49, 247, 201, 0.5) 50%, rgba(49, 247, 201, 0.5) 75%, rgba(255, 214, 66, 0.5) 75%);
  position: absolute;
  z-index: -1;
}
.background-colors-faded-3 {
  width: 100%;
  height: 110px;
  top: 330px;
  left: -231px;
  background: linear-gradient(-55deg,rgba(0,0,0,0) 75%,rgba(255, 214, 66, 0.25) 75%);
  position: absolute;
  z-index: -1;
}

.list-group-item {
  background-color: rgba(255,255,255,0.8) !important;
}

.input-group-text {
  font-size: 20px !important;
}

label {
  margin-bottom: 0px !important;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height .3s ease !important;
}

.custom-control-input.is-invalid:checked~.custom-control-label::before,
.was-validated .custom-control-input:invalid:checked~.custom-control-label::before {
  background-color: white !important;
  border-color: #6c757d !important;
}

.blabla>.custom-control-label::after {
  border-right: 2px solid lightgray !important;
  border-bottom: 2px solid lightgray !important;
}

.btn-success {
  color: #fff;
  border-color: #61C6FF !important;
  background-color: #61C6FF !important;
  box-shadow: none;
}

.btn-warning {
  color: #fff !important;
}

.custom-control {
  display: inline-block !important;
}

.list {
  margin-top: 60px;
  margin-left: 50px;
  margin-right: 50px;
  width: 95%;
}

.shrink-list {
  margin-top: 60px;
  margin-left: 50px;
  margin-right: 50px;
  width: 95%;
  -webkit-animation: shrink 0.2s forwards;
  animation: shrink 0.2s forwards;
}

.grow-list {
  margin-top: 60px;
  margin-left: 50px;
  margin-right: 50px;
  width: 75%;
  -webkit-animation: grow 0.2s forwards;
  animation: grow 0.2s forwards;
}

@-webkit-keyframes shrink {
  100% { width: 70%; }
}
@keyframes shrink {
  100% { width: 70%; }
}

@-webkit-keyframes grow {
  100% { width: 95%; }
}

@keyframes grow {
  100% { width: 95%; }
}

.show-side-bar {
  position: fixed;
  right: -25vw;
  width: 25vw;
  min-width: 300px;
  z-index: 3;
  height: 100vh;
  top: 0px;
  background: white;
  -webkit-animation: slide 0.2s forwards;
  animation: slide 0.2s forwards;
}

@-webkit-keyframes slide {
  100% { right: 0px; }
}

@keyframes slide {
  100% { right: 0px; }
}

.hide-side-bar {
  position: fixed;
  right: 0px;
  width: 20vw;
  height: 100vh;
  top: 0px;
  background: white;
  -webkit-animation: slide-out 0.2s forwards;
  animation: slide-out 0.2s forwards;
}

.left-side-bar {
  overflow-y:scroll;
  overflow-x:hidden;
  position: fixed;
  left: 0vw;
  width: 3vw;
  min-width: 40px;
  bottom: 0px;
  top: 0px;
  background: white;
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
  outline: 0 !important;
}

@-webkit-keyframes slide-out {
  100% { right: -20vw; }
}

@keyframes slide-out {
  100% { right: -20vw; }
}

.list-group-item.active {
  background-color: rgb(224, 243, 255) !important;
  color: black !important;
  border-color: lightgray !important;
}

.display-false {
  display: none;
}

.middle-section {
  margin-left: 20px;
}

.DateInput__small {
width: 110px !important;
}

label .custom-control .custom-checkbox {
  width: 100% !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.DateRangePicker_picker {
  background-color: #fff;
  top: 0px !important;
  z-index: 999;
}

.CalendarMonth_caption {
  font-size: 15px !important;
}

.coming-soon {
  background-color: rgb(247, 247, 247) !important;
  color: lightgray;
}

.custom-control-input:checked~.custom-control-label::before {
  background-color: rgb(0, 241, 185) !important;
}

.blabla>.custom-control-input:checked~.custom-control-label::before {
  background-color: transparent !important;
  border: 1px solid lightgray;
}

.btn-info {
  background-color: white!important;
  color: grey!important;
  border: none!important;
  padding: 5px!important;
}

.btn-secondary {
  background-color: white!important;
  color: grey!important;
  border: none!important;
}

.dropdown {
  display: inline;
  float: right;
}

.dropleft .dropdown-menu {
  top: 20px !important;
  right: 50% !important;
}

.group-todo-number {
  float: right;
  color: white;
  padding-top: 2px;
  padding-left: 8px;
  padding-right: 9px;
  padding-bottom: 1px;
  text-align: center;
  border-radius: 30px;
  font-size: 14px;
}

textarea {
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none !important;
}

.navlink {
  color: white !important;
}

.list-date {
  margin-right: 40px;
  float: right;
  margin-left: 20px;
  margin-right: 20px;
}

.list-favourite {
  float: right;
  margin-left: 20px;
  margin-right: 20px;
}

/* mobile css */

@media only screen and (max-width: 600px) {
  .middle-section {
    margin-left: 0px;
  }
  .middle-section-list-group-item {
    padding-right: 5px !important;
  }
  .left-side-bar-show {
    width: 100vw!important;
    overflow-y: scroll;
  }
  .left-side-bar {
    overflow-y:scroll;
    overflow-x:hidden;
    position: fixed;
    left: 0vw;
    width: 3vw;
    min-width: 40px;
    bottom: 0px;
    top: 0px;
    background: transparent;
    z-index: 100;
  }
  .left-side-bar>.list-group {
    width: 100vw;
    background-color: white;
    min-height: 100vh;
  }
  .show-side-bar {
    position: fixed;
    right: -25vw;
    width: 100vw;
    z-index: 1020;
    height: 100vh;
    top: 0px;
    left: 0px;
    background: white;
    -webkit-animation: slide 0.2s forwards;
    animation: slide 0.2s forwards;
  }
  .list-date {
    margin-right: 40px;
    float: right;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 8px;
    margin-top: 5px;
  }
  .list-favourite {
    float: right;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.dropdown>button {
  box-shadow: none !important;
}

.activity-feed {
  left: -150px !important;
  width: 250px;
  height: auto;
  max-height: 300px;
  overflow-x: hidden;
}

.list-group-item {
  font-size: 15px;
}

.reminder-checkbox>.custom-control-input:checked~.custom-control-label::before {
  background-color: #61C6FF !important;
}

input[type="file"] {
  display: none;
}

.modal {
  overflow-y: auto !important;
 }
 
 .modal-open {
  overflow: auto !important;
 }